@font-face {
    font-family: 'Cascadia';
    src: url('./fonts/Cascadia.ttf') format('truetype');
}

.wheel{
  color: aliceblue;
    font-family: 'Cascadia', sans-serif;
}

.wrapper {
    display: grid;
    place-items: center;
  }
  
  .typing-demo {
    width: 20ch;
    animation: typing 2s steps(20), blink 1s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: Cascadia;
    font-size: 1.5em;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }